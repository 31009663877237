export class EventDispatcher {
    private readonly _eventMap: Map<string, Function[]> = new Map();

    /**
     * Listens to the given event.
     */
    public on<T>(event: string, callback: (...args: any) => void): EventDispatcher {
        const callbacks = this._eventMap.get(event);

        if (Array.isArray(callbacks)) {
            callbacks.push(callback);

            return this;
        }

        this._eventMap.set(event, [callback]);

        return this;
    }

    /**
     * Unregisters the given event.
     */
    public off(event: string): EventDispatcher {
        this._eventMap.delete(event);
        return this;
    }

    /**
     * Dispatches a given event with some given data
     */
    public dispatch(event: string, ...args: any): EventDispatcher {
        const callbacks = this._eventMap.get(event);

        if (callbacks === undefined) {
            return this;
        }

        for (const callback of callbacks) {
            callback(...args);
        }

        return this;
    }
}
