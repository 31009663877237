export class Analytics {
    public static loadGoogleAnalytics(gaTrackingCode: string) {
        /* eslint-disable */
        const url = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingCode}`;

        const gaScript = document.createElement('script');

        gaScript.onload = () => {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            function gtag() { dataLayer.push(arguments); }
            // @ts-ignore
            gtag('js', new Date());
            // @ts-ignore
            gtag('config', gaTrackingCode);

            window.dispatchEvent(new Event('GTMLoaded'));
        };
        gaScript.src = url;

        document.head.appendChild(gaScript);
    }
}
