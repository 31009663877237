import axios, { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

export abstract class Repository {
    protected readonly _axios: AxiosInstance

    public constructor() {
        this._axios = applyCaseMiddleware(axios.create());

        this._axios.interceptors.request.use((config) => {
            const timestamp = (new URL(window.location.toString())).searchParams.get('t');

            if (timestamp === null) {
                return config;
            }

            config.params = {
                ...config.params,
                t: timestamp,
            };

            return config;
        });
    }
}
