import { SimpleModal } from './simple-modal.modal';
import { Restaurant } from '../repositories/address-validation/address-validation-response.types';
import { RestaurantUrl } from '../repositories/address-validation/restaurant-url.enum';
import { LandingParameters } from '../repositories/landing/landing-parameters.types';
import { ServiceType } from '../repositories/address-validation/service-type.enum';
import { TakeawayModal } from './takeaway-modal';

export class DistanceModal extends SimpleModal {
    public static mustShow(restaurant: Restaurant) {
        const { distance = Infinity } = restaurant;

        // Distance in meters
        if (distance >= 50000) {
            return true;
        }
        return false;
    }

    private readonly _acceptBtn: JQuery<HTMLLinkElement>

    public constructor(container: JQuery) {
        super(container);

        this._acceptBtn = this._container.find<HTMLLinkElement>('a.distance-modal__accept-btn');
    }

    private _setModalInfo(
        restaurant: Restaurant,
        { design: { colors }, settings: { configuration: { scope } } }: LandingParameters,
    ) {
        const [href] = TakeawayModal.getRestaurantUrl(
            restaurant, RestaurantUrl.ONLY_TAKEAWAY, ServiceType.TAKEAWAY, scope,
        );

        this._acceptBtn.attr('href', href);
        this._acceptBtn.css({
            'background-color': colors.buttonBackground,
            'color': colors.buttonText,
        });
    }

    public open(restaurant: Restaurant, parameters: LandingParameters) {
        this._setModalInfo(restaurant, parameters);

        window.dispatchEvent(new Event('DistanceModalOpened'));

        super.open();
    }
}
