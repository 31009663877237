export class Locale {
    private static _loadedPromise?: Promise<void>;

    private static readonly LANGUAGES = {
        en: 'static/locale/en.json',
        es: 'static/locale/es.json',
        pt: 'static/locale/pt.json',
        ca: 'static/locale/ca.json',
        fr: 'static/locale/fr.json',
    };

    private static updateTexts() {
        $('body').i18n();
    }

    public static loadTranslations(): Promise<void> {
        // @ts-ignore
        $.i18n({
            locale: Locale.getNavigatorLang(),
        });

        // @ts-ignore
        $.i18n.debug = false;

        // This prevents the promise from loading twice
        if (this._loadedPromise !== undefined) {
            return this._loadedPromise;
        }

        this._loadedPromise = new Promise<void>((resolve, reject) => {
            // @ts-ignore
            $.i18n().load(Locale.LANGUAGES).done(async () => {
                // @ts-ignore
                await $.i18n().load(Locale.getCustomLanguages());

                Locale.updateTexts();
                resolve();
            }).fail(reject);
        });

        return this._loadedPromise;
    }

    public static getNavigatorLang(): string {
        const mutations: { [key: string]: string } = {
            'gl': 'es-ES',
            'eu': 'es-ES',
        };

        const availableLanguages = Object.keys(Locale.LANGUAGES);

        return navigator.languages
            .map((lang) => {
                const mutation = mutations[lang] || lang;

                return mutation.split('-')[0];
            })
            .find((lang) => availableLanguages.includes(lang)) || 'en';
    }

    public static getCustomLanguages() {
        const languages = {};
        if (!window.CUSTOM_LANGUAGES) {
            return languages;
        }

        const customLanguages = window.CUSTOM_LANGUAGES ?? {};
        const availableLanguages = Object.keys(Locale.LANGUAGES);
        for (const lang of availableLanguages) {
            if (!customLanguages[lang]) {
                continue;
            }
            // @ts-ignore
            languages[lang] = customLanguages[lang];
        }

        return languages;
    }
}
