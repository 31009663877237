import { html, oneLineTrim } from 'common-tags';
import { SimpleModal } from './simple-modal.modal';
import { Restaurant } from '../repositories/address-validation/address-validation-response.types';
import { RestaurantUrl } from '../repositories/address-validation/restaurant-url.enum';
import { LandingParameters } from '../repositories/landing/landing-parameters.types';
import { ServiceType } from '../repositories/address-validation/service-type.enum';
import { LandingPage } from '../pages/landing/landing.page';
import { LandingScope } from '../repositories/landing/landing-scope.types';
import { DistanceModal } from './distance-modal';

export class TakeawayModal extends SimpleModal {
    public static getRestaurantUrl(
        restaurant: Restaurant,
        restaurantURL: RestaurantUrl,
        serviceType: ServiceType,
        scope: LandingScope[],
        address?: string,
    ): [string, boolean] {
        const { url, phone } = restaurant;

        const menuName = LandingPage.getRestaurantMenuName(
            restaurant, scope, serviceType,
        );

        const isPhone = url[restaurantURL] === null;

        return [isPhone
            ? `tel:${phone}`
            : oneLineTrim`
                    ${url[restaurantURL]}
                    ${menuName ? `&menu_name=${encodeURI(menuName)}` : ''}
                    ${address ? `&address=${encodeURI(address)}` : ''}
                `, isPhone];
    }

    private static START_IDX = 0;

    private _restaurants?: Restaurant[];
    private _landingParameters?: LandingParameters;

    private readonly _sliderContainer: JQuery;
    private readonly _info: JQuery;
    private readonly _orderBtn: JQuery<HTMLLinkElement>
    private readonly _distanceModal: DistanceModal;

    public constructor(container: JQuery) {
        super(container);

        this._sliderContainer = this._container.find('.takeaway-modal__slider');
        this._info = this._container.find('.takeaway-modal__info');
        this._orderBtn = this._container.find<HTMLLinkElement>('a.takeaway-modal__order-btn');

        this._distanceModal = new DistanceModal($('.distance-modal'));
    }

    protected _addEventListeners() {
        super._addEventListeners();

        this._container.on('click', '.btn', this._onBtnClick.bind(this));
    }

    private _onBtnClick(e: JQuery.ClickEvent) {
        const { target } = e;

        if (
            this._restaurants === undefined
            || this._landingParameters === undefined
            || !target.hasAttribute('data-idx')
        ) {
            return;
        }

        // eslint-disable-next-line radix
        const index = parseInt(target.getAttribute('data-idx'));

        const restaurant = this._restaurants[index];

        if (DistanceModal.mustShow(restaurant)) {
            e.preventDefault();
            this._distanceModal.open(restaurant, this._landingParameters);
        }
    }

    private _generateSlider(
        restaurants: Restaurant[],
        { design: { colors }, settings: { configuration: { scope } } }: LandingParameters,
    ) {
        this._tryToUnslick();
        this._sliderContainer.empty();

        for (const [index, restaurant] of restaurants.entries()) {
            const [href, isPhone] = TakeawayModal.getRestaurantUrl(
                restaurant, RestaurantUrl.ONLY_TAKEAWAY, ServiceType.TAKEAWAY, scope,
            );

            const scopeRestaurant = scope.find((s) => s.id === restaurant.id);
            const name = scopeRestaurant?.name ?? restaurant?.address ?? '';

            this._sliderContainer.append($(html`
                <div class="takeaway-modal__slider-item">
                    <div>
                        <div class="takeaway-modal__slider-item__name">
                            ${name}
                        </div>
                        <div class="takeaway-modal__slider-item__info">
                            <p>${restaurant.address}</p>
                            <p>${$.i18n('phone')}: ${restaurant.phone}</p>
                        </div>
                    </div>
                    <a
                            style="background-color: ${colors.buttonBackground}; color: ${colors.buttonText}"
                            class="takeaway-modal__slider-item__link btn api-btn"
                            data-idx="${index}"
                            href="${href}">
                        ${isPhone ? $.i18n('call') : $.i18n('takeaway_modal_order_btn')}
                    </a>
                </div>
            `));
        }

        this._slick();
        this._generateRestaurantInfo(TakeawayModal.START_IDX);
    }

    private _slick() {
        this._sliderContainer.slick({
            initialSlide: TakeawayModal.START_IDX,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<i '
                + 'class="lni lni-chevron-right takeaway-modal__slider-arrow takeaway-modal__slider-arrow--right"></i>',
            prevArrow: '<i '
                + 'class="lni lni-chevron-left takeaway-modal__slider-arrow takeaway-modal__slider-arrow--left"></i>',
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 992,
                },
            ],
        });

        this._sliderContainer.off('beforeChange').on('beforeChange', this._onSliderChange.bind(this));
    }

    private _onSliderChange(event: JQuery.Event, slick: any, currentSlide: number, nextSlide: number) {
        this._generateRestaurantInfo(nextSlide);
    }

    private _generateRestaurantInfo(index: number) {
        if (this._restaurants === undefined || this._landingParameters === undefined) {
            return;
        }

        const restaurant = this._restaurants[index];

        if (restaurant === undefined) {
            return;
        }

        this._info.empty();

        this._info.append($(html`
            <p>${restaurant.address}</p>
            <p>${$.i18n('phone')}: ${restaurant.phone}</p>
        `));

        const { settings: { configuration: { scope } } } = this._landingParameters;

        const [href, isPhone] = TakeawayModal.getRestaurantUrl(
            restaurant, RestaurantUrl.ONLY_TAKEAWAY, ServiceType.TAKEAWAY, scope,
        );

        this._orderBtn.attr('data-idx', index);

        this._orderBtn.attr('href', href);

        this._orderBtn.text(isPhone ? $.i18n('call') : $.i18n('takeaway_modal_order_btn'));
    }

    private _tryToUnslick() {
        if (!this._sliderContainer.hasClass('slick-initialized')) {
            return;
        }

        this._sliderContainer.slick('unslick');
    }

    public open(restaurants: Restaurant[], parameters: LandingParameters) {
        this._restaurants = restaurants;

        this._landingParameters = parameters;

        this._generateSlider(restaurants, parameters);

        window.dispatchEvent(new Event('TakeawayServiceModalOpened'));

        super.open();
    }
}
