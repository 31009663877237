import { gsap } from 'gsap';
import { Component } from '../common/component';

export class TitleComponent extends Component {
    private static _splitItem(item: JQuery) {
        // eslint-disable-next-line no-new
        new SplitText(item, {
            type: 'lines, words, chars',
            linesClass: 'line line--++',
            wordsClass: 'word word--++',
            charsClass: 'char char--++',
        });
    }

    private _titles: string[] = [];

    public set titles(titles: string[]) {
        this._titles = titles;

        this._container.empty();

        for (let i = 0; i < titles.length; i++) {
            const title = titles[i];

            const item = $(`<div class="slider__item slider__item--${i}">${title}</div>`);
            TitleComponent._splitItem(item);

            this._container.append(item);
        }

        this._regenerateSlick();

        this._addEventListeners();
    }

    private _regenerateSlick() {
        try {
            this._container.slick('unslick');
        } catch (e) {
            // Slick failed to stick, provably due it not being set yet!
        }

        this._container.slick({
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 4000,
            infinite: true,
            speed: 1200,
            fade: true,
            adaptiveHeight: false,
        });
    }

    /**
     * This creates each char animation
     */
    private _onBeforeChangeSlide(event: JQuery.Event, slick: any, currentSlide: number, nextSlide: number) {
        const nextSlideHtmlChar = this._container.find(`.slider__item--${nextSlide} .char`);

        nextSlideHtmlChar.length > 0 && gsap.set(nextSlideHtmlChar, { x: '0.5rem', opacity: 0.0001 });

        this._container.find(`.slider__item--${currentSlide} .line`).each((i, e) => {
            gsap.to($(e).find('.char'), {
                x: '-0.5rem', duration: 0.6, ease: 'power4.inOut', opacity: 0.0001, stagger: 0.05,
            });
        });

        this._container.find(`.slider__item--${nextSlide} .line`).each((i, e) => {
            gsap.to($(e).find('.char'), {
                x: '0%', duration: 0.6, ease: 'power4.inOut', opacity: 1, stagger: 0.05, delay: 0.6,
            });
        });
    }

    protected _addEventListeners() {
        this._container.off('beforeChange').on('beforeChange', this._onBeforeChangeSlide.bind(this));
    }
}
