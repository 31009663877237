import { EventDispatcher } from '../events/event-dispatcher';
import { LandingCustomization } from './landing/landing-customization';
import { LandingRepository } from '../repositories/landing/landing.repository';
import { LandingParameters } from '../repositories/landing/landing-parameters.types';

export abstract class Page extends EventDispatcher {
    protected readonly _landingRepository: LandingRepository;

    public static isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    public static hideLoading() {
        $('#loading').hide();
    }

    public static showLoading() {
        $('#loading').show();
    }

    public constructor() {
        super();

        this._landingRepository = new LandingRepository();
    }

    public hideLoading() {
        Page.hideLoading();
    }

    public showLoading() {
        Page.showLoading();
    }

    protected _onload(parameters: LandingParameters) {
        LandingCustomization.setCustomization(parameters);
    }

    public render(parameters: LandingParameters): Page {
        this._onload(parameters);

        return this;
    }
}
