import { Page } from './page';
import { LandingParameters } from '../repositories/landing/landing-parameters.types';
import { Analytics } from '../integrations/google/analytics/analytics';

export class AnalyticsPage extends Page {
    protected _onload(parameters: LandingParameters) {
        super._onload(parameters);

        const { settings: { configuration: { analytics: { google } } } } = parameters;

        if (google) {
            this._initGoogleAnalytics(google);
        }
    }

    private _initGoogleAnalytics(google: string) {
        Analytics.loadGoogleAnalytics(google);

        this._addAnalyticsEvents();
    }

    private _addAnalyticsEvents() {
        $('#delivery_btn')
            .on('click',
                () => window.ga?.('send', 'event', 'Cheerfy Landing buttons', 'click', 'Delivery'));
        $('#takeaway_btn')
            .on('click',
                () => window.ga?.('send', 'event', 'Cheerfy Landing buttons', 'click', 'Takeaway'));
        $('body')
            .on('click', '.autocomplete__item--geo-location',
                () => window.ga?.('send', 'event', 'Cheerfy Landing buttons', 'click', 'Geolocation'))
            .on('click', 'a',
                (e) => window.ga?.('send', 'event', 'Cheerfy Landing links', 'click', e.target.innerText));
    }
}
