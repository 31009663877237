import { Component } from '../components/common/component';

export class SimpleModal extends Component {
    private _overlay: JQuery | undefined

    public constructor(container: JQuery) {
        super(container);

        $('body').append(container);

        this._addEventListeners();
    }

    public close(): void {
        this._container.removeClass('simple-modal--open');

        setTimeout(() => {
            this._overlay?.remove();

            this._overlay = undefined;

            $('body').css('overflow', '');
        }, 300);

        this.dispatch('close');
    }

    public open(...args: any): void {
        $('body').css('overflow', 'hidden');

        this._container.css('display', 'block');

        requestAnimationFrame(() => {
            this._container.addClass('simple-modal--open');

            this._createOverlay();

            this._calculateZIndex();
        });

        this.dispatch('open');
    }

    private _createOverlay() {
        this._overlay = $('<div class="simple-modal__overlay"></div>');

        this._overlay.on('click', (e) => {
            if (e.target.classList.contains('simple-modal__overlay')) {
                this.close();
            }
        });

        $('body').append(this._overlay);
    }

    private _calculateZIndex() {
        const zIndex = $('.simple-modal__overlay').length + 1010;

        this._overlay?.css('z-index', zIndex);
        this._container.css('z-index', zIndex + 1);
    }

    protected _addEventListeners(): void {
        this._container.find('.simple-modal__close').on('click', this._onModalCloseClick.bind(this));
    }

    private _onModalCloseClick() {
        this.close();
    }
}
