import { AxiosResponse } from 'axios';
import { Repository } from '../repository';
import { ServiceType } from './service-type.enum';
import { AddressValidationResponse } from './address-validation-response.types';

export class AddressValidationRepository extends Repository {
    public validate(
        token: string,
        serviceType: ServiceType,
        address: string,
        latitude: number,
        longitude: number,
        locations: number[],
    ): Promise<AxiosResponse<AddressValidationResponse>> {
        return this._axios.post(`${window.__PARAMS__.cheerfyServicesBaseUrl}/shop-address-validation/`, {
            serviceType,
            address: {
                latitude,
                longitude,
                address,
            },
            scope: {
                locations,
            },
        }, {
            headers: {
                ...this._axios.defaults.headers,
                'Authorization': `token ${token}`,
            },
        });
    }
}
