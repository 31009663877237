import './scss/styles.scss';

import { Locale } from './core/integrations/locale';
import { LandingPage } from './core/pages/landing/landing.page';
import { LandingCustomization } from './core/pages/landing/landing-customization';
import { LandingRepository } from './core/repositories/landing/landing.repository';
import { isMalvonCateringLanding } from './core/pages/landing/landing-utils';

(async () => {
    LandingPage.showLoading();

    const { location } = window;
    let { hostname } = location;

    if (isMalvonCateringLanding()) {
        hostname = 'malvonempresas.cheerfy.com';
    }
    const landingRepository = new LandingRepository();
    const { data: parameters } = await landingRepository.getLandingParameters(hostname);

    LandingCustomization.setMetaTags(parameters);
    LandingCustomization.setCustomCodes(parameters);

    await LandingCustomization.loadDependencies();
    await Locale.loadTranslations();

    const page = new LandingPage();
    page.render(parameters);

    LandingPage.hideLoading();
})();
