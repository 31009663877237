import { Place } from './autocomplete.types';
import { Localstorage } from '../../localstorage/localstorage';
import { LocalstorageKey } from '../../localstorage/localstorage-key.enum';

export class AutocompleteCache {
    public static setPlace(cacheKey: string, place: Place) {
        const cachedPlaces = Localstorage.getItem(LocalstorageKey.PLACE_CACHE) ?? {};

        cachedPlaces[cacheKey] = place;

        Localstorage.setItem(LocalstorageKey.PLACE_CACHE, cachedPlaces);
    }

    public static getPlace(cacheKey: string): Place | undefined {
        const cachedPlaces = Localstorage.getItem(LocalstorageKey.PLACE_CACHE) ?? {};

        return cachedPlaces[cacheKey];
    }
}
