export enum AddressValidationStatus {
    ACTIVE = 1,
    OUT_OF_RANGE = 2,
    SUSPENDED = 3,
    NOT_POSSIBLE_TO_DELIVER = 4,
    INVALID_ADDRESS = 5,
    INVALID_CONTACT_DATA = 6,
    LOCATION_TEMPORARILY_UNAVAILABLE = 7,
    NO_DRIVERS_AVAILABLE = 8
}
