import { AxiosResponse } from 'axios';
import { Repository } from '../repository';
import { LandingParameters } from './landing-parameters.types';

export class LandingRepository extends Repository {
    public getLandingParameters(domain: string): Promise<AxiosResponse<LandingParameters>> {
        return this._axios.get(`${window.__PARAMS__.cheerfyBackendBaseUrl}/configuration/landing-page-data/`, {
            params: {
                domainName: domain,
            },
        });
    }
}
