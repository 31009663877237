import { EventDispatcher } from '../../events/event-dispatcher';

export abstract class Component extends EventDispatcher {
    protected readonly _container: JQuery;

    public constructor(container: JQuery) {
        super();

        if (container.length === 0) {
            throw new Error('No container found');
        }

        this._container = container;
    }

    protected abstract _addEventListeners(): void;
}
