import { LandingParameters } from '../../repositories/landing/landing-parameters.types';

export class LandingCustomization {
    public static async loadDependencies() {
        const stylesBasePath = 'static/css';
        const scriptsBasePath = 'static/js/libs';
        const {
            __PARAMS__: { cheerfyMapApiKey: mapsApiKey },
        } = window;

        const staticsBasePath = window.__PARAMS__.cheerfyStaticsBaseUrl;

        await LandingCustomization.loadJS(`${staticsBasePath}/scripts/cookies/cookies.js?v=${window.CS_VERSION}`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/splitText.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/slick.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/jquery.validate.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.messagestore.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.fallbacks.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.language.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.parser.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.emitter.min.js`);
        await LandingCustomization.loadJS(`${scriptsBasePath}/jquery/i18n/jquery.i18n.emitter.bidi.min.js`);
        await LandingCustomization.loadJS(`https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`);

        await LandingCustomization.loadCSS(`${stylesBasePath}/LineIcons/LineIconsCustom.css`);
        await LandingCustomization.loadCSS(`${stylesBasePath}/slick.min.css`);
        await LandingCustomization.loadCSS(
            'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap',
        );
    }

    public static setCustomization(parameters: LandingParameters) {
        const { design: { colors, images, fontFamily }, settings } = parameters;

        window.CookiesManagement?.init({
            font: fontFamily,
            bgColor: colors?.navigationBar,
            textColor: colors?.buttonBackground,
            primaryBtnBgColor: colors?.buttonBackground,
            primaryBtnTextColor: colors?.buttonText,
        });

        LandingCustomization.setFontFamily(fontFamily);
        LandingCustomization.setAlertMessage(parameters);

        document.querySelectorAll<HTMLImageElement>('img.logo')
            .forEach((l) => {
                l.src = images?.logo ?? 'https://static.cheerfy.com/static-resources/default_logo.png';
                l.onload = () => LandingCustomization.calculateMainHeight();
            });

        document.querySelectorAll<HTMLImageElement>('img.background')
            .forEach((b) => {
                b.src = images?.background ?? 'https://static.cheerfy.com/static-resources/default_bg.png';
            });

        document.querySelectorAll<HTMLHeadingElement>('.header')
            .forEach((header) => {
                header.style.backgroundColor = colors.navigationBar;
            });

        document.querySelectorAll<HTMLHeadingElement>('.alert-container')
            .forEach((header) => {
                header.style.backgroundColor = colors.buttonText;
                header.style.color = colors.navigationBar;
            });

        document.querySelectorAll<HTMLElement>('.btn-api')
            .forEach((button) => {
                button.style.backgroundColor = colors.buttonBackground;
                button.style.color = colors.buttonText;
            });
    }

    public static setCustomCodes(parameters: LandingParameters) {
        const { settings: { configuration: { headCode, bodyCode } } } = parameters;

        headCode && $('head').append(headCode);
        bodyCode && $('body').append(bodyCode);
    }

    public static setMetaTags(parameters: LandingParameters) {
        const {
            settings: {
                texts: { metaTitle, metaDescription } = {},
            },
            design: { images } = {},
        } = parameters;

        if (metaTitle) {
            document.title = metaTitle;
        }

        if (metaDescription) {
            document.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
        }

        LandingCustomization
            .setFavicon(images?.favicon ?? 'https://static.cheerfy.com/static-resources/default_logo.png');
    }

    public static setFavicon(url: string) {
        let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.querySelector('head')?.appendChild(link);
        }

        link.href = url;
    }

    public static setFontFamily(fontFamily: string) {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css?family=${fontFamily}&display=swap`;
        link.rel = 'stylesheet';

        document.body.append(link);

        const style = document.createElement('style');
        style.innerHTML = `body * { font-family: "${fontFamily}" }`;
        document.body.appendChild(style);

        document.body.style.fontFamily = fontFamily;
    }

    public static setAlertMessage(parameters: LandingParameters) {
        const { settings: { texts: { alert } = {} } } = parameters;

        if (alert === undefined) {
            return;
        }

        const alertText = document.createElement('p');
        alertText.innerHTML = alert;
        document.querySelector('.alert-container')?.appendChild(alertText);
    }

    public static calculateMainHeight() {
        const { innerHeight } = window;

        const header = document.querySelector<HTMLHeadingElement>('.header');

        if (header === null) {
            return;
        }

        const main = document.querySelector<HTMLDivElement>('.main');

        if (main === null) {
            return;
        }

        main.style.minHeight = `${innerHeight - header.offsetHeight}px`;
    }

    public static loadCSS(src: string) {
        return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.href = src;
            link.rel = 'stylesheet';
            link.onload = resolve;
            link.onerror = resolve;

            document.body.append(link);
        });
    }

    public static loadJS(src: string) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = resolve;

            document.body.append(script);
        });
    }
}
