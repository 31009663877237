import { html } from 'common-tags';

import { Component } from '../../../components/common/component';
import { Place } from './autocomplete.types';
import { AutocompleteCache } from './autocomplete-cache';
import { Page } from '../../../pages/page';

export class Autocomplete extends Component {
    public static toCheerfyPlace(googlePlace: google.maps.places.PlaceResult): Place {
        return {
            lat: googlePlace.geometry?.location?.lat() ?? 0,
            long: googlePlace.geometry?.location?.lng() ?? 0,
            formattedAddress: googlePlace.formatted_address ?? '',
            addressComponents: googlePlace.address_components ?? [],
        };
    }

    /**
     *  Generates a html element surrounded between given tags from a given text and given offsets,
     *  adapted from fuzzysort
     *  @see {@link https://github.com/farzher/fuzzysort}
     */
    public static highlight(text: string, offsets: number[], hOpen?: string, hClose?: string) {
        const openTag = hOpen ?? '<b>';
        const closeTag = hClose ?? '</b>';

        let highlighted = '';
        let matchesIndex = 0;
        let opened = false;

        for (let i = 0; i < text.length; ++i) {
            const char = text[i];
            if (offsets[matchesIndex] === i) {
                ++matchesIndex;
                if (!opened) {
                    opened = true;
                    highlighted += openTag;
                }

                if (matchesIndex === offsets.length) {
                    highlighted += char + closeTag + text.substr(i + 1);
                    opened = false;
                    break;
                }
            } else if (opened) {
                opened = false;
                highlighted += closeTag;
            }
            highlighted += char;
        }

        if (opened) {
            highlighted += closeTag;
        }

        return highlighted;
    }

    public static getCurrentPosition(): Promise<GeolocationPosition> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    private readonly _input: HTMLInputElement;
    private readonly _results: HTMLDivElement;

    private _currentPlace?: Place;

    private readonly _autocompleteService: google.maps.places.AutocompleteService;
    private readonly _placesService: google.maps.places.PlacesService;
    private readonly _geocoder: google.maps.Geocoder;

    private _alpha2: string | string[] | null = null;

    public static getTypeFromComponent(components: google.maps.GeocoderAddressComponent[] | undefined, type: string) {
        return components?.find((c) => c.types.includes(type));
    }

    /**
     * @see https://developers.google.com/maps/documentation/places/web-service/details#sessiontoken
     */
    private _sessionToken: google.maps.places.AutocompleteSessionToken;

    public constructor(container: JQuery) {
        super(container);

        this._sessionToken = this._createSessionToken();

        const [input] = this._container.find<HTMLInputElement>('input.autocomplete__input');
        this._input = input;

        const [results] = this._container.find<HTMLDivElement>('div.autocomplete__results');
        this._results = results;

        this._autocompleteService = new google.maps.places.AutocompleteService();

        const [autoCompleteServiceContainer] = this._container.find<HTMLDivElement>('div.autocomplete__service');

        this._placesService = new google.maps.places.PlacesService(autoCompleteServiceContainer);

        this._geocoder = new google.maps.Geocoder();

        if (navigator.geolocation) {
            this._results.append(this._generateGeoLocationTemplate());
        }

        this._addEventListeners();
    }

    public set alpha2(alpha2: string | string[] | null) {
        this._alpha2 = alpha2;
    }

    public setPlaceFomCache(cacheKey: string) {
        const place = AutocompleteCache.getPlace(cacheKey);

        if (place === undefined) {
            return;
        }

        this._onPlaceDetailResponse(place, google.maps.places.PlacesServiceStatus.OK, undefined);
    }

    public getCurrentPlace() {
        return this._currentPlace;
    }

    private _createSessionToken(): google.maps.places.AutocompleteSessionToken {
        return new google.maps.places.AutocompleteSessionToken();
    }

    private _emptyResults() {
        $(this._results).find('>:not(.prevent-remove)').remove();
    }

    private _getScrollAtTopDependencies() {
        const main = document.querySelector<HTMLDivElement>('main.main');
        const title = document.querySelector<HTMLDivElement>('div#title');
        const mainButtons = document.querySelector<HTMLDivElement>('div.main__buttons');

        if (
            main === null
            || title === null
            || mainButtons == null
        ) throw new Error('Cannot get scroll divs');

        return [
            main,
            title,
            mainButtons,
        ];
    }

    private _setScrollAtTop() {
        setTimeout(() => {
            const [main, title, mainButtons] = this._getScrollAtTopDependencies();

            mainButtons.style.display = 'none';
            main.style.display = 'block';
            main.style.marginTop = `-${title.offsetHeight}px`;
            title.style.zIndex = '-1';

            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 250);
    }

    private _unsetScrollAtTop() {
        const [main, title, mainButtons] = this._getScrollAtTopDependencies();

        mainButtons.style.display = '';
        main.style.display = '';
        main.style.marginTop = '';
        title.style.zIndex = '';
    }

    private _setActiveStatus() {
        this._container.addClass('autocomplete--active');

        Page.isMobile() && this._setScrollAtTop();
    }

    private _setInactiveStatus() {
        this._container.removeClass('autocomplete--active');

        Page.isMobile() && this._unsetScrollAtTop();
    }

    private _getPlacePredictions(search: string): Promise<{
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
    }> {
        return new Promise((resolve) => {
            this._autocompleteService.getPlacePredictions({
                input: search,
                types: ['address'],
                componentRestrictions: {
                    country: this._alpha2,
                },
                sessionToken: this._sessionToken,
            }, (a, b) => resolve({
                predictions: a,
                status: b,
            }));
        });
    }

    private _getPlaceDetails(placeId: string): Promise<{
        placeResult: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
    }> {
        return new Promise(((resolve, reject) => {
            this._placesService.getDetails({
                placeId,
                fields: [
                    'formatted_address',
                    'address_components',
                    'geometry.location',
                ],
                sessionToken: this._sessionToken,
            }, (a, b) => {
                // The session token gets invalidated when getDetails is called.
                this._sessionToken = this._createSessionToken();

                resolve({
                    placeResult: a,
                    status: b,
                });
            });
        }));
    }

    private _geocode(lat: number, lng: number): Promise<{
        geocoderResult: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus
    }> {
        return new Promise((resolve) => {
            this._geocoder.geocode({
                location: {
                    lat,
                    lng,
                },
            }, (a, b) => {
                resolve({
                    geocoderResult: a,
                    status: b,
                });
            });
        });
    }

    /**
     * Clears the input
     */
    public clearInput() {
        this._input.value = '';
    }

    protected _addEventListeners() {
        google.maps.event.addDomListener(this._input, 'focus', this._onInputFocus.bind(this));
        google.maps.event.addDomListener(this._input, 'keyup', this._onInputKeyUp.bind(this));
        // @ts-ignore
        $(document).on('click touchend', this._onDocumentClick.bind(this));

        this._container
            .off('click', '.autocomplete__item.autocomplete__item--geo-location')
            .on(
                'click',
                '.autocomplete__item.autocomplete__item--geo-location',
                this._onGeoLocationClick.bind(this),
            );
    }

    private _onDocumentClick(event: MouseEvent) {
        const element = <HTMLElement>event.target;

        if (
            !((element.parentElement?.className !== 'autocomplete')
                && (element.parentElement?.className !== 'autocomplete__item')
                && (element.className !== 'autocomplete__item')
                && (element.className !== 'autocomplete__item__main-match')
                && (element.className !== 'autocomplete__match')
                && (element.tagName !== 'INPUT')
                && document.activeElement !== this._input
            )
        ) {
            return;
        }

        this._input.blur();

        this._setInactiveStatus();
    }

    private async _onInputKeyUp(event: KeyboardEvent) {
        this.dispatch('keyup', event);

        if (this._input.value === '') {
            this._emptyResults();
            return;
        }

        this._setActiveStatus();

        const { predictions, status } = await this._getPlacePredictions(this._input.value);
        this._onPredictionResponse(predictions, status);
    }

    private _onInputFocus(event: FocusEvent) {
        this._setActiveStatus();
    }

    private async _onGeoLocationClick(event: JQuery.ClickEvent) {
        try {
            const { coords: { latitude, longitude } } = await Autocomplete.getCurrentPosition();

            const cacheKey = `${latitude}/${longitude}`;

            let place = AutocompleteCache.getPlace(cacheKey);

            if (place === undefined) {
                const response = await this._geocode(latitude, longitude);

                if (response.geocoderResult === null || response.geocoderResult.length === 0) {
                    return;
                }

                place = Autocomplete.toCheerfyPlace(response.geocoderResult[0]);

                this._onGeocodeResponse(place, response.status, cacheKey);

                AutocompleteCache.setPlace(cacheKey, place);

                return;
            }

            this._onGeocodeResponse(place, google.maps.GeocoderStatus.OK, cacheKey);
        } catch (e) {
            // Something went wrong!
            console.error(e);
        }
    }

    private async _onPredictionClick(event: JQuery.ClickEvent, prediction: google.maps.places.AutocompletePrediction) {
        this._setInactiveStatus();
        let place = AutocompleteCache.getPlace(prediction.place_id);

        // Check if we have a cached version of the place
        if (place === undefined) {
            const details = await this._getPlaceDetails(prediction.place_id);

            if (details.placeResult === null) {
                return;
            }

            place = Autocomplete.toCheerfyPlace(details.placeResult);

            this._onPlaceDetailResponse(place, details.status, prediction.place_id);

            AutocompleteCache.setPlace(prediction.place_id, place);

            return;
        }

        this._onPlaceDetailResponse(place, google.maps.places.PlacesServiceStatus.OK, prediction.place_id);
    }

    private _onGeocodeResponse(place: Place, status: google.maps.GeocoderStatus, cacheKey: string) {
        if (status !== google.maps.GeocoderStatus.OK) {
            return;
        }

        this._onPlaceResult(place, cacheKey, 'geocode');
    }

    private _onPlaceDetailResponse(
        place: Place,
        status: google.maps.places.PlacesServiceStatus,
        cacheKey: string | undefined,
    ) {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return;
        }

        this._onPlaceResult(place, cacheKey, 'placeChanged');
    }

    private _onPlaceResult(
        place: Place,
        cacheKey: string | undefined,
        event: string,
    ) {
        this._currentPlace = place;

        this.dispatch(event, place, cacheKey);

        this._input.value = place.formattedAddress;
    }

    private _onPredictionResponse(
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus,
    ) {
        this._emptyResults();

        // Place service status error
        if (status !== google.maps.places.PlacesServiceStatus.OK || predictions === null) {
            this._results.innerHTML += `
                <div class="autocomplete__item autocomplete__item--error">${$.i18n('no_result_search')}</div>
            `;

            return;
        }

        for (const prediction of predictions) {
            const template = this._generateItemTemplate(prediction);
            this._results.append(template);
        }
    }

    private _generateGeoLocationTemplate() {
        const domElement = html`
            <div class="autocomplete__item autocomplete__item--geo-location prevent-remove">
                <i class="lni lni-map-marker"></i>
                <span>${$.i18n('use_my_current_location')}</span>
            </div>
        `;

        return $<HTMLDivElement>(domElement)[0];
    }

    private _generateItemTemplate(prediction: google.maps.places.AutocompletePrediction): HTMLDivElement {
        const matchedTemplateMain = this._generateMatchedTemplate(
            prediction.structured_formatting.main_text_matched_substrings,
            prediction.structured_formatting.main_text,
        );

        const matchedTemplateSecondary = this._generateMatchedTemplate(
            prediction.structured_formatting.main_text_matched_substrings,
            prediction.structured_formatting.secondary_text,
        );

        const domElement = html`
            <div class="autocomplete__item">
                <span class="autocomplete__item__main-match">${matchedTemplateMain}</span>
                <span>${matchedTemplateSecondary}</span>
            </div>
        `;

        const template = $<HTMLDivElement>(domElement);

        template.on('click', (e) => this._onPredictionClick(e, prediction));

        return template[0];
    }

    private _generateMatchedTemplate(
        matchedSubstrings: google.maps.places.PredictionSubstring[],
        text: string,
    ) {
        const offsetIndexes = matchedSubstrings.map(
            (match) => Array((match.offset + match.length) - match.offset + 1)
                .fill(0)
                .map((_, idx) => match.offset + idx),
        );

        const offsets = (<number[]>[]).concat(...offsetIndexes);

        return Autocomplete.highlight(text, offsets);
    }
}
