import { LocalstorageKey } from './localstorage-key.enum';
import { LocalstorageItemMap } from './localstorage-item-map.types';

export class Localstorage {
    public static getItem<T extends LocalstorageKey>(key: T): LocalstorageItemMap[T] | undefined {
        const item = localStorage.getItem(key);

        if (item === null) {
            return undefined;
        }

        return JSON.parse(item);
    }

    public static setItem<T extends LocalstorageKey>(key: T, item: LocalstorageItemMap[T]) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    public static removeItem<T extends LocalstorageKey>(key: T) {
        localStorage.removeItem(key);
    }

    public static clear() {
        localStorage.clear();
    }
}
