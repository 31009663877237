import { html } from 'common-tags';
import { EnumValues } from 'enum-values';
import { Page } from '../page';
import { TitleComponent } from '../../components/title/title.component';
import { Autocomplete } from '../../integrations/google/maps/autocomplete';
import { LandingCustomization } from './landing-customization';
import { LandingServiceStatus } from '../../repositories/landing/landing-service-status.enum';
import { ServiceType } from '../../repositories/address-validation/service-type.enum';
import { AddressValidationRepository } from '../../repositories/address-validation/address-validation.repository';
import { LandingParameters } from '../../repositories/landing/landing-parameters.types';
import { AddressValidationStatus } from '../../repositories/address-validation/address-validation-status.enum';
import { TakeawayModal } from '../../modals/takeaway-modal';
import { DistanceModal } from '../../modals/distance-modal';
import { Restaurant } from '../../repositories/address-validation/address-validation-response.types';
import { RestaurantUrl } from '../../repositories/address-validation/restaurant-url.enum';
import { ExternalServiceType } from '../../repositories/address-validation/external-service-type.enum';
import { LandingScope, LandingScopeExternalService } from '../../repositories/landing/landing-scope.types';
import { AnalyticsPage } from '../analyticsPage';
import { Localstorage } from '../../integrations/localstorage/localstorage';
import { LocalstorageKey } from '../../integrations/localstorage/localstorage-key.enum';
import { Place } from '../../integrations/google/maps/autocomplete.types';
import { isMalvonCateringLanding } from './landing-utils';

export class LandingPage extends AnalyticsPage {
    public static getRestaurantMenuName(restaurant: Restaurant, scopes: LandingScope[], service: ServiceType) {
        return scopes
            .find((s) => s.id === restaurant.id)?.services[service === ServiceType.TAKEAWAY ? 'takeaway' : 'delivery']
            .menuName;
    }

    private static _showMessage(message: string) {
        const messageContainer = $('.main__message');
        messageContainer.show();
        messageContainer.html(message);
    }

    private static _hideError() {
        $('.main__message').hide();
    }

    private readonly _addressValidationRepository: AddressValidationRepository;

    private readonly _titleComponent: TitleComponent;
    private readonly _autocomplete: Autocomplete;

    private readonly _autocompleteContainer: JQuery<HTMLDivElement>;
    private readonly _takeAwayBtn: JQuery<HTMLButtonElement>;
    private readonly _deliveryBtn: JQuery<HTMLButtonElement>;

    private readonly _takeawayModal: TakeawayModal;
    private readonly _distanceModal: DistanceModal;

    private _place?: Place;
    private _landingParameters?: LandingParameters;

    public constructor() {
        super();

        this._addressValidationRepository = new AddressValidationRepository();

        this._titleComponent = new TitleComponent($('#title'));

        this._autocomplete = new Autocomplete($('#autocomplete'));

        this._autocompleteContainer = $<HTMLDivElement>('.main__autocomplete');
        this._takeAwayBtn = $<HTMLButtonElement>('#takeaway_btn');
        this._deliveryBtn = $<HTMLButtonElement>('#delivery_btn');

        this._takeawayModal = new TakeawayModal($('.takeaway-modal'));
        this._distanceModal = new DistanceModal($('.distance-modal'));

        this._addEventListeners();
    }

    private _addEventListeners() {
        window.addEventListener('resize', LandingCustomization.calculateMainHeight);

        this._takeAwayBtn.on('click', this._onTakeAwayBtnClick.bind(this));
        this._deliveryBtn.on('click', this._onDeliveryBtnClick.bind(this));

        this._autocomplete.on('keyup', this._onAutocompleteKeyup.bind(this));
        this._autocomplete.on('placeChanged', this._onPlaceChanged.bind(this));
        this._autocomplete.on('geocode', this._onGeocode.bind(this));
    }

    private _processStatusError(statusCode: AddressValidationStatus, restaurants: Restaurant[]) {
        switch (statusCode) {
        case AddressValidationStatus.OUT_OF_RANGE:
            this._disableButton(ServiceType.DELIVERY);
            LandingPage._showMessage(
                this._composeDeliveryErrorMessage($.i18n('error_out_of_range'), restaurants),
            );
            break;
        case AddressValidationStatus.SUSPENDED:
            this._disableButtons();
            LandingPage._showMessage($.i18n('error_suspended'));
            break;
        case AddressValidationStatus.NO_DRIVERS_AVAILABLE:
        case AddressValidationStatus.LOCATION_TEMPORARILY_UNAVAILABLE:
        case AddressValidationStatus.NOT_POSSIBLE_TO_DELIVER:
            this._disableButton(ServiceType.DELIVERY);
            LandingPage._showMessage(
                this._composeDeliveryErrorMessage($.i18n('error_cannot_deliver'), restaurants),
            );
            break;
        case AddressValidationStatus.INVALID_ADDRESS:
        case AddressValidationStatus.INVALID_CONTACT_DATA:
            this._disableButtons();
            LandingPage._showMessage($.i18n('error_invalid_address'));
            break;
        default:
            LandingPage._showMessage($.i18n('error_unknown'));
        }
    }

    private _composeDeliveryErrorMessage(initialText: string, restaurants: Restaurant[]): string {
        if (this._landingParameters === undefined) {
            return initialText;
        }

        const { settings: { configuration: { scope } } } = this._landingParameters;

        const externalServices = EnumValues.getValues(ExternalServiceType);

        const [closestRestaurant] = scope
            .filter((s) => Object.entries(s?.services ?? {})
                .some(
                    ([key, value]) => externalServices.includes(key)
                        && (<LandingScopeExternalService>value).url !== '',
                ))
            .sort(((a, b) => {
                const aRestaurant = restaurants.find((r) => r.id === a.id);
                const bRestaurant = restaurants.find((r) => r.id === b.id);

                return (aRestaurant?.distance ?? Infinity) - (bRestaurant?.distance ?? Infinity);
            }));

        if (closestRestaurant === undefined) {
            return initialText;
        }

        const container = $('<div/>');

        const deliverLinks = Object.entries(closestRestaurant.services)
            .filter(
                ([key, value]) => {
                    const { url } = <LandingScopeExternalService>value;
                    return externalServices.includes(key) && url;
                },
            )
            .map(([key, value]) => {
                const i18nKey = key.toLocaleLowerCase() ?? '';
                const { url } = <LandingScopeExternalService>value;

                return html`
                    <a href="${url}">${$.i18n(i18nKey)}</a>
                `;
            })
            .join(', ');

        if (deliverLinks.length === 0) {
            return initialText;
        }

        const text = `<p class="main__message__delivery">${$.i18n('order_through_text')} ${deliverLinks}</p>`;

        container.append(initialText, '<br/>', text);

        return container[0].innerHTML;
    }

    private _getEnabledLocationIds(serviceType: ServiceType, parameters: LandingParameters) {
        return parameters.settings.configuration.scope
            .filter((scope) => scope
                .services?.[
                    serviceType === ServiceType.TAKEAWAY
                        ? 'takeaway' : 'delivery'
                ]?.status === LandingServiceStatus.ENABLED)
            .map((scope) => scope.id) ?? [];
    }

    private async _onBtnClick(serviceType: ServiceType) {
        if (this._landingParameters === undefined || this._place === undefined) {
            return;
        }

        const locations = this._getEnabledLocationIds(serviceType, this._landingParameters);

        try {
            const { data: { statusCode, restaurants } } = await this._addressValidationRepository.validate(
                window.__PARAMS__.cheerfyServicesToken,
                serviceType,
                this._place.formattedAddress,
                this._place.lat,
                this._place.long,
                locations,
            );

            if (statusCode !== AddressValidationStatus.ACTIVE) {
                this._processStatusError(statusCode, restaurants);

                return;
            }

            const activeRestaurants = restaurants.filter(
                (r: Restaurant) => r.statusCode === AddressValidationStatus.ACTIVE,
            );

            if (activeRestaurants.length === 0) {
                this._processStatusError(AddressValidationStatus.SUSPENDED, restaurants);

                return;
            }

            switch (serviceType) {
            case ServiceType.TAKEAWAY:
                this._onTakeAwaySuccess(activeRestaurants);
                break;
            case ServiceType.DELIVERY:
                this._onDeliverySuccess(activeRestaurants);
                break;
            default:
                break;
            }
        } catch (e) {
            LandingPage._showMessage($.i18n('error_unknown'));
            this._discoverActiveBtn();
        }
    }

    private _onAutocompleteKeyup(event: KeyboardEvent) {
        this._disableButtons();
    }

    private _onDeliverySuccess(restaurants: Restaurant[]) {
        const [restaurant] = restaurants;

        if (this._place === undefined || this._landingParameters === undefined) {
            return;
        }

        const { settings: { configuration: { scope } } } = this._landingParameters;

        const [href] = TakeawayModal.getRestaurantUrl(
            restaurant, RestaurantUrl.ONLY_DELIVERY, ServiceType.DELIVERY, scope, this._place?.formattedAddress,
        );

        window.dispatchEvent(new CustomEvent('SingleRestaurantRedirect', {
            'detail': {
                restaurant,
                scopeRestaurant: scope.find((s) => s.id === restaurant.id),
            },
        }));
        this._redirect(href);
    }

    private _onTakeAwaySuccess(restaurants: Restaurant[]) {
        if (this._landingParameters === undefined) {
            return;
        }

        if (restaurants.length === 1) {
            const [restaurant] = restaurants;

            if (DistanceModal.mustShow(restaurant)) {
                this._distanceModal.open(restaurant, this._landingParameters);

                return;
            }

            const { settings: { configuration: { scope } } } = this._landingParameters;

            const [href] = TakeawayModal.getRestaurantUrl(
                restaurant, RestaurantUrl.ONLY_TAKEAWAY, ServiceType.TAKEAWAY, scope, this._place?.formattedAddress,
            );

            window.dispatchEvent(new CustomEvent('SingleRestaurantRedirect', {
                'detail': {
                    restaurant,
                    scopeRestaurant: scope.find((s) => s.id === restaurant.id),
                    takeaway: true,
                },
            }));
            this._redirect(href);

            return;
        }

        this._takeawayModal.open(restaurants, this._landingParameters);
    }

    private _redirect(url: string) {
        LandingPage._showMessage($.i18n('redirecting_msg'));

        setTimeout(() => {
            window.location.href = url;
        }, 500);
    }

    private async _onTakeAwayBtnClick(event: JQuery.ClickEvent) {
        this._onBtnClick(ServiceType.TAKEAWAY);
    }

    private _onDeliveryBtnClick(event: JQuery.ClickEvent) {
        this._onBtnClick(ServiceType.DELIVERY);
    }

    private _onGeocode(place: Place, cacheKey: string | undefined) {
        this._onPlaceChanged(place, cacheKey);
    }

    private _onPlaceChanged(place: Place, cacheKey: string | undefined) {
        this._disableButtons();

        LandingPage._hideError();

        this._place = undefined;

        const streetNumber = Autocomplete.getTypeFromComponent(place.addressComponents, 'street_number');

        if (streetNumber === undefined) {
            LandingPage._showMessage($.i18n('street_number_error'));

            return;
        }

        if (cacheKey !== undefined) {
            Localstorage.setItem(LocalstorageKey.PLACE_KEY, cacheKey);
        }

        this._place = place;

        this._discoverActiveBtn();
    }

    private _hideButton(serviceType: ServiceType) {
        switch (serviceType) {
        case ServiceType.TAKEAWAY:
            this._takeAwayBtn.addClass('hide');
            break;
        case ServiceType.DELIVERY:
            this._deliveryBtn.addClass('hide');
            break;
        default:
            break;
        }
    }

    private _showButton(serviceType: ServiceType) {
        switch (serviceType) {
        case ServiceType.TAKEAWAY:
            this._takeAwayBtn.removeClass('hide');
            break;
        case ServiceType.DELIVERY:
            this._deliveryBtn.removeClass('hide');
            break;
        default:
            break;
        }
    }

    private _showButtons() {
        this._showButton(ServiceType.TAKEAWAY);
        this._showButton(ServiceType.DELIVERY);
    }

    private _hideButtons() {
        this._hideButton(ServiceType.TAKEAWAY);
        this._hideButton(ServiceType.DELIVERY);
    }

    private _disableButton(serviceType: ServiceType) {
        switch (serviceType) {
        case ServiceType.TAKEAWAY:
            this._takeAwayBtn.attr('disabled', 'true');
            break;
        case ServiceType.DELIVERY:
            this._deliveryBtn.attr('disabled', 'true');
            break;
        default:
            break;
        }
    }

    private _enableButton(serviceType: ServiceType) {
        switch (serviceType) {
        case ServiceType.TAKEAWAY:
            this._takeAwayBtn.removeAttr('disabled');
            break;
        case ServiceType.DELIVERY:
            this._deliveryBtn.removeAttr('disabled');
            break;
        default:
            break;
        }
    }

    private _enableButtons() {
        this._enableButton(ServiceType.TAKEAWAY);
        this._enableButton(ServiceType.DELIVERY);
    }

    private _disableButtons() {
        this._disableButton(ServiceType.TAKEAWAY);
        this._disableButton(ServiceType.DELIVERY);
    }

    private _getCountryCodes() {
        const countries: string[] = [];
        this._landingParameters?.settings.configuration.scope.forEach(({ countryCode = '' }: LandingScope) => {
            if (countryCode.length === 0 || countries.includes(countryCode)) {
                return;
            }
            countries.push(countryCode);
        });
        return countries;
    }

    protected _onload(parameters: LandingParameters) {
        super._onload(parameters);

        const {
            settings: { texts: { title } = {} },
        } = parameters;

        if (Array.isArray(title)) {
            this._titleComponent.titles = title;
        }

        this._landingParameters = parameters;

        this._autocomplete.alpha2 = this._getCountryCodes();

        const placeKey = Localstorage.getItem(LocalstorageKey.PLACE_KEY);

        if (placeKey !== undefined) {
            this._autocomplete.setPlaceFomCache(placeKey);
        }
    }

    private _discoverActiveBtn() {
        const hasDelivery = !!this._landingParameters?.settings.configuration.scope
            .find((scope) => scope?.services?.delivery?.status === LandingServiceStatus.ENABLED);

        const hasTakeaway = !!this._landingParameters?.settings.configuration.scope
            .find((scope) => scope?.services?.takeaway?.status === LandingServiceStatus.ENABLED);

        if (hasDelivery) {
            this._enableButton(ServiceType.DELIVERY);
            this._showButton(ServiceType.DELIVERY);
        }

        if (hasTakeaway) {
            this._enableButton(ServiceType.TAKEAWAY);
            this._showButton(ServiceType.TAKEAWAY);
        }

        this._autocompleteContainer.attr('data-multiple-services', String(hasDelivery && hasTakeaway));
    }

    public render(parameters: LandingParameters): Page {
        const render = super.render(parameters);

        LandingCustomization.calculateMainHeight();

        return render;
    }
}
